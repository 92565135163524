import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import PackagingList from './components/Pages/PackagingList/PackagingList';

import StickerView from './components/Pages/StickerView/StickerView';
// import QRPayment from './components/Pages/QRPayment/QRPayment';
import Login from './components/Pages/Login/Login';
import FoodTracker from './components/Pages/FoodTracker/FoodTracker';
import { Provider } from 'react-redux';
import store from './store';
import './App.css';
import './css/common.css';

function App() {
  return (
    <div className="App">
      <Provider store={store}>
      <Router>
        <div className="contentWrapper">
        <Switch>
            <Route exact path="/" component={FoodTracker}/>
            <Route exact path="/sticker" component={StickerView}/>
            <Route exact path="/packaging" component={PackagingList}/>
            <Route path="/login" component={Login}/>
        </Switch>
        </div>
      </Router>
      </Provider>
    </div>
  );
}

export default App;
